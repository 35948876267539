/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import { ThemeProvider } from "./src/context/ThemeContext";
;

export const wrapRootElement = ({ element }) => (
    <ThemeProvider>{element}</ThemeProvider>
)

export const onClientEntry = async () => {
    // Load polyfills

    if (typeof IntersectionObserver === `undefined`) {
      await import(`intersection-observer`);
    }

    if (typeof URLSearchParams === `undefined`) {
      await import(`url-search-params-polyfill`);
    }

    await import(`date-time-format-timezone/build/browserified/date-time-format-timezone-golden-zones-no-locale`);

    await import(`svgxuse`);

    await import(`object-fit-images`).then((objectFitImages) => {
      objectFitImages.default();
    });
}