// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-generic-js": () => import("./../../../src/templates/Generic.js" /* webpackChunkName: "component---src-templates-generic-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-what-we-do-js": () => import("./../../../src/templates/WhatWeDo.js" /* webpackChunkName: "component---src-templates-what-we-do-js" */),
  "component---src-templates-work-item-js": () => import("./../../../src/templates/WorkItem.js" /* webpackChunkName: "component---src-templates-work-item-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/Work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

