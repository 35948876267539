import React from "react";

const defaultState = {
    current: null,
    setTheme: () => {}
};

const themes = {
    DARK: {
        '--theme-color': '#fff',
        '--theme-bg': '#0043B3',
    },
    LIGHT: {
        '--theme-color': '#0043B3',
        '--theme-bg': '#fff',
    }
}

const ThemeContext = React.createContext(defaultState);

class ThemeProvider extends React.Component {
    state = {
        current: null,
    }

    setTheme = ( theme ) => {
        const current = typeof theme === 'string' ? themes[ theme ] : theme;
        this.setState({ current });
    }

    render() {
        const { children } = this.props;
        const { current } = this.state;

        return (
            <ThemeContext.Provider
                value={{
                    current,
                    setTheme: this.setTheme,
                }}
            >
                {children}
            </ThemeContext.Provider>
        )
    }
}
export default ThemeContext
export { ThemeProvider, themes }